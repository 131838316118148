.navbar {
  flex: none;
  width: 48px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0 24px 0;
  text-align: center;
}

.navbar.active {
  background: var(--ssColorWhite);
}

.navbar.lite {
  background: transparent;
}

.navbar.right {
  position: absolute;
  right: 0;
  transition: background 0.14s;
}

.navbarTop {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.navbarBottom {
  flex-grow: 0;
}
