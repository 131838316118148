.touch {
  cursor: pointer;
  transition: all 0.14s;
}

.touch:hover > .touch__preview,
.touch:hover > * {
  opacity: 0.8;
}

.touch:active {
  box-shadow: 0 0 0px 2px var(--ssColorBlue);
}
