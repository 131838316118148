.tggl {
  position: relative;
  width: 40px;
  height: 20px;
  flex: none;
  /* cursor: pointer; */
  display: inline-block;
}

.tggl__checkbox {
  position: absolute;
  opacity: 0;
}

.tggl__background,
.tggl__button {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 300ms;
}

.tggl__background {
  width: 100%;
  height: 100%;
  background: var(--ssColorGray02);
  border-radius: 24px;
  cursor: pointer;
}

.tggl__button {
  width: 50%;
  height: 100%;
  border-radius: 50%;
  background: var(--ssColorWhite);
  border: 2px solid transparent;
  box-sizing: border-box;
  background-clip: content-box;
  pointer-events: none;
}

.tggl__checkbox:checked ~ .tggl__button {
  transform: translateX(100%);
}

.tggl__checkbox:checked ~ .tggl__background {
  background: var(--ssColorBlue);
}

.tggl__checkbox:disabled ~ .tggl__button,
.tggl__checkbox:disabled ~ .tggl__background {
  pointer-events: none;
  cursor: default;
}

.tggl__checkbox:disabled ~ .tggl__background {
  opacity: 0.5;
}

.tggl__checkbox:active ~ .tggl__background,
.tggl__checkbox:focus ~ .tggl__background {
  box-shadow: var(--ssFocusShadow);
}
