.relative {
  position: relative;
}

.sticky {
  position: sticky;
  z-index: 100000;
}

.fixed {
  position: fixed;
}
