.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.8);
}

.overlay > * {
  outline: 0;
  margin: 0 auto !important;
}

.popup {
  width: 600px;
  height: 600px;
  background: #fff;
  text-align: center;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.popover {
  position: absolute;
  z-index: 99999;
  width: 200px;
  height: 240px;
  background: #fff;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
}
