.xs {
  font-size: 12px;
}
.sm {
  font-size: 14px;
}
.md {
  font-size: 16px;
}
.lg {
  font-size: 20px;
}
.xl {
  font-size: 24px;
}
