.mask {
  overflow: hidden;
}

.square {
  composes: mask;
  border-radius: 0;
}

.rounded {
  composes: mask;
  border-radius: 4px;
}

.circle {
  composes: mask;
  border-radius: 50%;
}
