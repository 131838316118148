.container {
  width: calc(100% + 8px); /* TODO find better solution */
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.column {
  position: relative;
  padding: 4px;
  width: 20%;
}

.img {
  border-radius: 4px;
}

.del {
  position: absolute;
  z-index: 99999;
  top: -8px;
  right: -8px;
  width: 16px;
  height: 16px;
  line-height: 14px;
  text-align: center;
  vertical-align: middle;
  color: var(--ssColorWhite);
  background: var(--ssColorOrange);
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  user-select: none;
  font-size: 15px;
}

.del:hover,
.img:hover + .del {
  opacity: 1;
}
