.container {
  margin: 0 -24px;
}

.container.sorting {
  user-select: none;
  pointer-events: none;
}

.item {
  position: relative;
  z-index: 99999;
  height: 40px;
  line-height: 40px;
  font-size: 14px;

  display: flex;
  justify-content: space-between;

  padding-left: 24px;
  padding-right: 12px;

  cursor: move;
}

.icon {
  flex: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  text-align: center;
  color: var(--ssColorGray02);
  cursor: pointer;
}

.icon:hover {
  color: var(--ssColorBlack);
}
