.table {
  width: 100%;
}

.header {
  position: sticky;
  top: 0;
  z-index: 9999999;
  background: #fff;
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.body {
  overflow-y: auto;
  overflow-x: hidden;
}

.sorting {
  user-select: none;
  pointer-events: none;
}

.row {
  display: flex;

  height: 48px;

  font-size: 12px;
  line-height: 1.4;

  border-top: 1px solid var(--ssColorGray00);
}

.header .row {
  border-top: none;
}

.body .row:hover {
  background: var(--ssColorBlackAlpha05);
}

.row.dragging {
  z-index: 999999;
  background: var(--ssColorBlackAlpha08);
  border: 0;
}

.cell {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cell:first-child {
  padding-left: 24px;
}

.cell:last-child {
  padding-right: 24px;
}

.column {
  color: var(--ssColorGray03);
  cursor: pointer;
  user-select: none;
  /* border-bottom: 1px solid transparent; */
}

.column:hover {
  color: var(--ssColorGray06);
}

.column[sorting]:after {
  margin-left: 4px;
}

.column[sorting='0']:after {
  content: '↑';
}

.column[sorting='1']:after {
  content: '↓';
}

.tableIcon {
  width: 48px;
  text-align: center;
  color: var(--ssColorGray02);
  cursor: pointer;
}

.tableIcon * {
  visibility: hidden;
}

.tableIcon.tableIconShow * {
  visibility: visible;
}

.tableIcon:hover {
  color: var(--ssColorGray03);
}

.row:hover .tableIcon * {
  visibility: visible;
}
