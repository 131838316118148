.box--padding-1 {
  padding: 4px;
}

.box--padding-x-1 {
  padding-left: 4px;
  padding-right: 4px;
}

.box--padding-y-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.box--padding-2 {
  padding: 8px;
}

.box--padding-x-2 {
  padding-left: 8px;
  padding-right: 8px;
}

.box--padding-y-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.box--padding-3 {
  padding: 12px;
}

.box--padding-x-3 {
  padding-left: 12px;
  padding-right: 12px;
}

.box--padding-y-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.box--padding-4 {
  padding: 16px;
}

.box--padding-x-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.box--padding-y-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.box--padding-5 {
  padding: 20px;
}

.box--padding-x-5 {
  padding-left: 20px;
  padding-right: 20px;
}

.box--padding-y-5 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.box--padding-6 {
  padding: 24px;
}

.box--padding-x-6 {
  padding-left: 24px;
  padding-right: 24px;
}

.box--padding-y-6 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.box--margin-top-1 {
  margin-top: 4px;
}

.box--margin-top-2 {
  margin-top: 8px;
}

.box--margin-top-3 {
  margin-top: 12px;
}

.box--margin-top-4 {
  margin-top: 16px;
}

.box--margin-top-5 {
  margin-top: 20px;
}

.box--margin-top-6 {
  margin-top: 24px;
}

.box--margin-top-7 {
  margin-top: 28px;
}

.box--margin-top-8 {
  margin-top: 32px;
}

.box--margin-top-9 {
  margin-top: 36px;
}

.box--margin-top-10 {
  margin-top: 40px;
}

.box--margin-top-11 {
  margin-top: 44px;
}

.box--margin-top-12 {
  margin-top: 48px;
}
