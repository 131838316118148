.footer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-top: 1px solid;
  border-radius: 0 0 4px 4px;
  overflow: hidden;

  color: var(--ssColorGray03);
  background: var(--ssColorGray00);
  border-color: var(--ssColorGray02);
}
