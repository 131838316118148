.system {
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.system {
  width: 600px;
  margin: 0 auto;
}

.color {
  width: 100px;
  height: 100px;
  border-radius: 16px;
}

.color.gray00 {
  background: var(--ssColorGray00);
}
.color.gray01 {
  background: var(--ssColorGray01);
}
.color.gray02 {
  background: var(--ssColorGray02);
}
.color.gray03 {
  background: var(--ssColorGray03);
}
.color.gray04 {
  background: var(--ssColorGray04);
}
.color.gray05 {
  background: var(--ssColorGray05);
}
.color.gray06 {
  background: var(--ssColorGray06);
}
