.background {
  width: 100%;
  background: var(--ssColorGray00);
  background-image: linear-gradient(
    to right,
    var(--ssColorGray00) 0%,
    var(--ssColorGray01) 20%,
    var(--ssColorGray00) 40%,
    var(--ssColorGray00) 100%
  );
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  animation: Shimmer 1400ms linear infinite forwards;
}
@keyframes Shimmer {
  0% {
    background-position: -100vw 0;
  }
  100% {
    background-position: 100vw 0;
  }
}
.placeholder {
  color: #000;
  background: #000;
  mix-blend-mode: lighten;
  height: 100%;
}
