.page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.header {
  /* position: relative; */
  color: var(--ssColorWhite);
  text-align: center;
  font-size: 32px;
  margin-bottom: 24px;
}

.headerLink,
.headerSpan {
  color: var(--ssColorWhite);
  opacity: 0.5;
}

.headerLink.active {
  opacity: 1;
  text-decoration: underline;
}

.modal {
  position: relative;
  width: 400px;
  background: var(--ssColorWhite);
  border-radius: 4px;
  padding: 40px 24px;
  overflow: hidden;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);

  text-align: center;
}

.message {
  margin-bottom: 24px;
  color: #e91e63;
}

/* .form {
  border: 1px solid var(--ssColorGray01);
  border-radius: 4px;
  overflow: hidden;
} */

.input {
  position: relative;
  width: 100%;
  height: 60px;
  padding: 0 16px;
  background: #fafafa;
  border: 0;
  /* border-radius: 4px; */
  /* outline-color: var(--ssColorBlue); */
  font-size: 24px;

  text-align: center;

  outline: 0;

  border: 1px solid var(--ssColorGray01);
  margin-top: -1px;
}

.input:first-child {
  border-radius: 4px 4px 0 0;
}

.input:hover {
  border-color: var(--ssColorGray02);
  z-index: 1;
}

.input:focus {
  border-color: var(--ssColorBlue);
  z-index: 1;
}

/* .input + .input {
  margin-top: 8px;
  border-top: 1px solid var(--ssColorGray01);
} */

.button {
  width: 100%;
  height: 60px;
  border: 0;
  outline: 0;
  cursor: pointer;
  color: var(--ssColorWhite);
  background: var(--ssColorGray02);
  font-size: 24px;
  text-transform: capitalize;
  /* margin-bottom: 16px; */

  border-radius: 0 0 4px 4px;
  transition: all 0.14s;
  pointer-events: none;
}

.button.valid {
  background: var(--ssColorBlue);
  pointer-events: auto;
}

.button:hover {
  background: var(--ssColorBlueDark);
}

.reset {
  display: inline-block;
  margin-top: 40px;
  color: var(--ssColorBlue);
}

.reset:hover {
  text-decoration: underline;
  color: var(--ssColorBlueDark);
}

.text {
  color: var(--ssColorGray03);
  font-size: 14px;
  line-height: 1.4;
  margin-top: 40px;
}

.link {
  color: var(--ssColorBlue);
}

.link:hover {
  text-decoration: underline;
  color: var(--ssColorBlueDark);
}
