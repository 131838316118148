.search {
  width: 100%;
  height: 32px;
  padding: 0 16px;
  border: 0;
  outline: 0;
  border-radius: 9999px;
  transition: all 0.14s linear;
  background: var(--ssColorGray00);
}

.search:hover {
  background: var(--ssColorGray01);
}

.search:focus {
  background: var(--ssColorWhite);
  box-shadow: 0 0 0px 2px var(--ssColorBlue);
}
