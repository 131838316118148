.menuItem {
  color: var(--ssColorGray03);
}

.menuItem:hover,
.menuItem.hover {
  color: var(--ssColorGray06);
  background: var(--ssColorBlackAlpha05);
}

.menuItem.active,
.menuItem:active {
  color: var(--ssColorBlue);
  background: var(--ssColorBlackAlpha08);
}
