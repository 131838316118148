.arrow {
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 100px;
  height: 100%;
  color: var(--ssColorWhite);
  border: 0;
  outline: 0;
  opacity: 0;
  transition: opacity 0.14s;
  cursor: pointer;
}

.arrow:hover {
  opacity: 1;
}

.prev {
  left: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.next {
  right: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}

.img {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}
