.preview {
  position: relative;

  width: 416px;
  height: 100vh;
  flex: none;
  display: flex;
  align-items: center;

  transition: all 0.5s;

  flex-grow: 1;
}

.preview.preview--hidden {
  width: 0;
  padding: 0;
}

.preview__resize {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  height: 100%;
  width: 16px;
  /* opacity: 0.5; */
  cursor: col-resize;
  cursor: e-resize;

  /* width: 48px; */
  transition: all 0.14s;
  width: 8px;
  border-left: 2px solid transparent;
  /* border-right: 3px solid transparent; */
  /* background-clip: content-box;
  box-sizing: border-box; */
}

.preview__resize--right {
  left: auto;
  right: 0;
  border-left: 0;
  border-right: 2px solid transparent;
  cursor: default;
}

.preview__resize:hover {
  /* background-color: var(--ssColorBlue); */
  border-color: #2196f3;
  /* transition: all 5s; */
  transition-delay: 500ms;
}

.preview__center {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  /* top: 0; */
  /* bottom: 0; */
  /* margin: auto; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview__device {
  width: 100%;
  display: flex;
  align-items: center;
}

.preview .preview__device {
  transition: all 0.5s !important;
  opacity: 1;
}

.preview--hidden .preview__device {
  opacity: 0;
}

.preview__device {
  position: relative;
  width: 320px;
  height: 570px;
  flex: none;
  margin: 0 auto;
  padding: 0;

  color: var(--ssColorWhite);
  background: var(--ssColorBlack);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.14s ease-out;

  overflow: hidden;
}
