:root {
  /* --ssTypeFamilySans: 'Trebuchet MS', sans-serif; */
  /* --ssTypeFamilySans: 'Tahoma', sans-serif; */
  --ssFontFamilySans: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica,
    'Apple Color Emoji', Arial, sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
  --ssFontFamilySerif: sans-serif;
  --ssFontFamilyMonospace: monospace;

  /* font size */
  --ssTypeFontSizeGigantic: 60px;
  --ssTypeFontSizeJumbo: 48px;
  --ssTypeFontSizeXXLarge: 36px;
  --ssTypeFontSizeXLarge: 30px;
  --ssTypeFontSizeLarge: 24px;
  --ssTypeFontSizeMedium: 18px;
  --ssTypeFontSizeSmall: 14px;
  --ssTypeFontSizeXSmall: 12px;

  /* letter spacing */
  --ssTypeLetterSpacingGigantic: -1px;
  --ssTypeLetterSpacingJumbo: -0.72px;
  --ssTypeLetterSpacingXXLarge: -0.54px;
  --ssTypeLetterSpacingXLarge: -0.45px;
  --ssTypeLetterSpacingLarge: 0.36px;
  --ssTypeLetterSpacingMedium: 0;
  --ssTypeLetterSpacingSmall: 0;
  --ssTypeLetterSpacingXSmall: 0;

  /* font weight */
  --ssTypeFontWeightBold: 700;
  --ssTypeFontWeightMedium: 500;
  --ssTypeFontWeightBook: 400;
  --ssTypeFontWeightLight: 300;
  --ssTypeFontWeightXLight: 200;

  /* line height */
  --ssTypeLineHeightTight: 20px;
  --ssTypeLineHeightStandard: 24px;
  --ssTypeLineHeightExtra: 32px;
  --ssTypeLineHeightHuge: 40px;
}
