.button {
  position: relative;

  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;

  cursor: pointer;

  background: none;
  border: 0;

  box-sizing: border-box;
  padding: 0;
  margin: 0;

  font-family: inherit;
  text-align: center;
  vertical-align: middle;

  transition: all 0.14s;
}

.button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.sm {
  min-width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;

  padding: 0 16px;
}
.md {
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;

  padding: 0 20px;
}
.lg {
  min-width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;

  padding: 0 24px;
}

.fill {
  width: 100%;
  height: 100%;
}

.fit {
  height: 100%;
}

.button.rounded {
  border-radius: 9999px;
}

.button.block {
  width: 100%;
  display: block;
}

.button.square {
  padding: 0;
}

.icon {
  margin-left: 8px;
}
