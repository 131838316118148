*::-webkit-scrollbar {
  position: absolute;
  -webkit-appearance: none;
  width: 8px;
}
/* *::-webkit-scrollbar-track {
} */
*::-webkit-scrollbar-thumb {
  background-clip: padding-box !important;
  border-radius: 4px;
  color: #fff;
}
*::-webkit-scrollbar-track {
  background: #f3f3f3;
  /* box-shadow: inset 0 -4px 0 0, inset 0 4px 0 0; */
}
*::-webkit-scrollbar-thumb {
  background: rgba(40, 40, 40, 0.4);
  /* box-shadow: inset 0 -2px, inset 0 -3px, inset 0 2px, inset 0 3px; */
  min-height: 36px;
}
*::-webkit-scrollbar-corner {
  background: #fff;
}

.app {
  display: flex;

  height: 100vh;
  overflow: hidden;

  background-size: cover;
  background-position: bottom center;
  background-attachment: fixed;

  transition: background-image 0.05s ease-in;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 40px;

  user-select: none;
}

.flex {
  display: flex;
}

.flex.flex--center {
  align-items: center;
}

.flex.flex--between {
  justify-content: space-between;
}

.flex.flex--column {
  flex-direction: column;
}

.header-input {
  padding: 0 16px;
  border: 0;
  outline: 0;
  height: 32px;
  border-radius: 24px;
  background: var(--ssColorGray00);
}

.header-select {
  padding: 0 16px;
  border: 0;
  outline: 0;
  height: 32px;
  border-radius: 24px;
  border: 1px solid var(--ssColorGray01);
}

[data-tooltip]::after {
  content: attr(data-tooltip);

  position: absolute;
  top: 0;
  left: 100%;
  bottom: 0;
  z-index: 999999999;

  margin: auto;
  padding: 0px 8px;

  height: 22px;
  line-height: 22px;

  font-size: 14px;

  color: var(--ssColorWhite);
  background: var(--ssColorBlue);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  opacity: 0;
  visibility: hidden;
  transition: all 0.14s;

  pointer-events: none;
}

[data-tooltip]:hover::after {
  visibility: visible;
  opacity: 1;
  transition-delay: 1s;
  transform: translateX(4px);
}

.dashboard {
  display: flex;
  flex-grow: 1;
  z-index: 100;
}

.content {
  position: relative;
  width: 100%;
  background: var(--ssColorWhite);

  transition: all 0.21s;
  flex: none;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1), 0 0 15px 0 rgba(0, 0, 0, 0.2);

  overflow-y: auto;
  flex: auto;
}

.content:empty {
  display: none;
}

.page {
  min-height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.page__placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  color: #555;
  text-align: center;
}

.form {
  position: relative;
}

/* overflow */

.breakWord {
  hyphens: auto;
  word-wrap: break-word;
}

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
