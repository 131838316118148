.image {
  display: block;
}

.fill {
  height: 100%;
  width: 100%;
}

.contain {
  /* composes: fill; */
  /* background-size: contain; */
  object-fit: contain;
}

.cover {
  /* composes: fill; */
  /* background-size: cover; */
  object-fit: cover;
}
