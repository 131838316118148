.input-place-suggestions {
  position: absolute;
  top: 100%;
  background-color: white;
  /* border: 1px solid #555555; */
  border: 0;
  width: 100%;
  z-index: 999999;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);
}

.suggestion-item {
  font-size: 14px;
  background-color: #ffffff;
  padding: 10px;
  color: #555555;
  cursor: pointer;
}

.suggestion-item:hover {
  color: var(--ssColorGray06);
  background: var(--ssColorBlackAlpha05);
}
