.notifications {
  position: fixed;
  /* right: 80px; */
  /* bottom: 40px; */
  /* left: 0; */
  right: 48px;
  bottom: 40px;
  z-index: 99999999999;

  /* width: 50%; */
  /* padding: 40px; */

  margin: 0 auto;

  /* display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
  align-items: flex-end; */
}

.notification {
  width: 320px;
  /* background: var(--ssColorBlackAlpha08); */
  background: #fff;
  /* padding: 24px; */
  border-radius: 4px;
  margin-top: 8px;
  animation: appear 0.14s;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(16px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
