.group {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px 12px;
}

.group.block {
  display: block;
}

.group.block .aside {
  max-width: 100%;
  padding-top: 0;
  padding-bottom: 12px;
}

.group + .group {
  border-top: 1px solid var(--ssColorGray00);
}

.aside {
  flex: none;
  min-width: 30%;
  max-width: 50%;

  padding: 10px 12px;
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: var(--ssColorGray06);
}

.descr {
  font-size: 12px;
  color: var(--ssColorGray03);
  margin-bottom: 8px;
}

.content {
  width: 100%;
  padding: 0 12px;
}
