.checkbox {
  position: relative;
  display: inline-flex;
  outline: 0;
}

.input {
  /* display: none; */
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.label {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid var(--ssColorGray02);
  transition: all 0.14s;
  cursor: pointer;
  outline: 0;
}

.label:hover {
  border-color: var(--ssColorBlue);
}

.label:active,
.input:focus ~ .label {
  /* border-color: var(--ssColorBlue); */
  box-shadow: var(--ssFocusShadow);
  outline: 0;
}

.label svg {
  display: block;
  fill: #fafafa;
  stroke-width: 3px;
  transform: scale(0);
  transition: all 0.14s ease-in;
}

.input:checked + .label {
  border-color: var(--ssColorBlue);
}

.input:checked + .label svg {
  transform: scale(1);
  background: var(--ssColorBlue);
}

.input[type='radio'] + .label,
.label.radio {
  border-radius: 50%;
  position: relative;
}

.input[type='radio'] + .label:after,
.label.radio:after {
  content: '';
  background: var(--ssColorBlue);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
  transition: all 0.14s ease-in;
}

.input[type='radio']:checked + .label:after {
  transform: scale(0.8);
}

.input:disabled + .label {
  background: var(--ssColorGray01);
  border-color: var(--ssColorGray01);
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}
