.item {
  height: 72px;
  /* width: 100%; */
  display: flex;
  align-items: center;
  /* padding: 8px; */
  color: #000;
  font-size: 14px;
  padding: 0 16px;
}

.item.reverse {
  flex-direction: row-reverse;
}

.item.right {
  padding-right: 0;
  justify-content: space-between;
}

.item + .item {
  border-top: 1px solid rgba(40, 40, 40, 0.1);
}

.preview {
  margin-right: 16px;
}

.square {
  width: 56px;
  height: 56px;
  background: #c4c4c4;
  border-radius: 8px;
  overflow: hidden;
}

.content {
  flex: 1;
}

.title {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: bold;
  color: #111;
}

.descr {
  font-size: 14px;
  color: #757575;
}

.avatar {
  width: 56px;
  height: 56px;
  background: #c4c4c4;
  border-radius: 50%;
  overflow: hidden;
}
