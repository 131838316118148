.banner {
  position: relative;
  width: 100%;
  margin: 16px 0;
}

.image {
  width: 100%;
  height: 128px;
  object-fit: cover;
  border-radius: 4px;

  cursor: pointer;
  transition: all 0.14s;
}

.image:hover {
  opacity: 0.8;
}
