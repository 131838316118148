.heading {
  font-weight: 500;
  color: var(--ssColorGray03);
}

.xs {
  font-size: 14px;
}
.sm {
  font-size: 16px;
}
.md {
  font-size: 18px;
}
.lg {
  font-size: 24px;
}
.xl {
  font-size: 32px;
}
