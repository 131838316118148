.badge {
  display: inline-block;

  font-size: 8px;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  padding: 4px 6px;
  border-radius: 9999px;
}

.badge.default {
  color: var(--ssColorWhite);
  background: var(--ssColorOrangeLight);
}

.badge.middle {
  vertical-align: middle;
}

.badge.top {
  vertical-align: super;
}
