.relative {
  position: relative;
}

.preview {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  /* width: 50%; */
  width: 40px;
  border: 1px solid transparent;
  box-sizing: border-box;
  background-clip: content-box;
  border-radius: 0 4px 4px 0;
  /* pointer-events: none; */

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

input:focus ~ .preview,
.preview:hover {
  background-color: var(--ssColorGray00);
}

.color {
  width: 16px;
  height: 16px;
  border-radius: 50%;

  background: #fff;
}
