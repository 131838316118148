.popup {
  position: relative;
  margin: 40px 0;
}

.header {
  position: relative;
  color: var(--ssColorWhite);
  text-align: center;
  font-size: 32px;
  margin-bottom: 24px;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  user-select: none;
  opacity: 0.4;
  transition: opacity 0.14s ease-in;
}

.close:hover {
  opacity: 1;
}

.content {
  position: relative;
  width: 600px;
  min-height: 300px;
  background: var(--ssColorWhite);
  border-radius: 4px;
}

.content.content--nopadding {
  padding: 0;
}

.contentHeader {
  position: relative;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}

.tabs {
  position: absolute;
  top: 0;
  left: -100px;

  list-style: none;
  margin: 0;
  padding: 0;
}

.button {
  width: 80px;
  height: 80px;
  /* line-height: 80px; */
  margin-bottom: 8px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  color: var(--ssColorWhite);
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  opacity: 0.4;
  outline: 0;

  user-select: none;
}

.button:hover {
  opacity: 0.6;
}

.button.buttonActive {
  opacity: 1;
}

.buttonTitle {
  font-size: 12px;
  margin-top: 8px;
}
