.slider {
  overflow: hidden;
}

.slides {
  display: flex;
}

.slides > * {
  flex: none;
  width: 100%;
}
