.sidebar {
  display: flex;
  position: relative;
  background: var(--ssColorWhite);
}

.menu {
  width: 240px;
  transition: all 0.5s;
}

.menu:empty {
  display: none;
}
