.upload {
  position: relative;
  height: 100%;
  background-size: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  color: #fff;
  background: hsla(0, 0%, 0%, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.14s;
  cursor: pointer;
}

.upload:hover .overlay {
  visibility: visible;
  opacity: 1;
}
