:root {
  --ssColorWhite: #ffffff;
  --ssColorGray00: hsla(240, 10%, 95%, 1);
  --ssColorGray01: hsla(240, 10%, 90%, 1);
  --ssColorGray02: hsla(240, 10%, 85%, 1);
  --ssColorGray03: #555555;
  --ssColorGray06: #141414;
  --ssColorBlack: #000000;

  --ssColorBlackAlpha05: hsla(240, 100%, 10%, 0.05);
  --ssColorBlackAlpha08: hsla(240, 100%, 10%, 0.08);

  --ssColorPink: #e80a89;
  --ssColorRed: #de3636;
  --ssColorOrange: #f96816;
  --ssColorOrangeLight: #ff7b39;
  --ssColorYellow: #fff59d;
  --ssColorYellowDark: #fff066;
  --ssColorGreenLight: #86ce21;
  --ssColorGreen: #23aa5a;
  --ssColorLightBlue: #2196f3;
  --ssColorBlue: #1565c0;
  --ssColorBlueDark: #135cae;
}
