.stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  height: 140px;

  padding: 16px 24px;
  padding-right: 40%;

  color: var(--ssColorGray06);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  overflow: hidden;
}

.card:hover {
  border-color: var(--ssColorBlue);
}

.card + .card {
  border-top: 1px solid var(--ssColorGray01);
}
