.overlay {
  position: absolute;
  top: 0;
  left: 0;
  /* right: 0; */
  /* bottom: 0; */
  z-index: 99999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  background: rgba(255, 255, 255, 0.4);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 40px;
  height: 40px;
  background-color: #673ab7;
  /* background-color: #F06292; */

  /* margin: 100px auto; */
  animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation-direction: alternate;
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px);
    background-color: yellow;
  }
  50% {
    transform: perspective(120px) rotateY(180deg);
    background-color: #673ab7;
  }
  100% {
    transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    background-color: #f06292;
  }
}
