.tooltip {
  padding: 4px 8px;
  font-size: 12px;
  color: var(--ssColorWhite);
  background: var(--ssColorGray03);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  white-space: nowrap;
}

.ghost {
  position: fixed;
  z-index: 999999999;
  pointer-events: none;
}

.align {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ttooltip {
  position: absolute;
  top: 0;
  left: 100%;
  bottom: 0;
  z-index: 999999999;

  margin: auto;
  padding: 0px 8px;

  height: 22px;
  line-height: 22px;

  font-size: 14px;

  color: var(--ssColorWhite);
  background: var(--ssColorBlue);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  opacity: 0;
  visibility: hidden;
  transition: all 0.14s;

  pointer-events: none;
}
