@import url('https://fonts.googleapis.com/css?family=Roboto');

.app {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', 'San Francisco', sans-serif;
  color: var(--ssColorWhite);
  user-select: none;
  overflow: hidden;
}

.app div::-webkit-scrollbar {
  display: none;
}

.statusbar {
  position: absolute;
  top: 0;
  z-index: 999999;
  width: 100%;
  height: 24px;
  background: black;
  opacity: 0.1;
}

.surface {
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 100%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1), 0 0 15px 0 rgba(0, 0, 0, 0.2);
  background: #514bc0;
}

.navbar {
  height: 56px;
  margin-top: 24px;

  display: flex;
  align-items: center;
}

.navbar__title {
  width: 100%;
  padding: 0 8px;
  text-align: center;
  font-weight: bold;
}

.navbar__button {
  flex: none;
  width: 56px;
  height: 56px;
  text-align: center;
  line-height: 56px;
  vertical-align: middle;
}

.navbar__button:hover {
  background: rgba(0, 0, 0, 0.1);
}

/*phone-only*/
@media (max-width: 599px) {
  .app {
    position: fixed;
    z-index: 99999999999;
  }
  .statusbar {
    display: none;
  }
  .navbar {
    padding-top: 0;
    height: 56px;
  }
}

.body {
  width: 100%;
  height: 100%;
  background: #fff;
  padding-top: 80px;
  /* padding-bottom: 16px; */
  overflow-x: hidden;
  overflow-y: auto;
}

.body::-webkit-scrollbar {
  display: none;
}

.title3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.0015em;

  color: #333333;
}

.subtitle1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.0015em;
}

.subtitle2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.001em;

  color: #757575;
}

.date {
  width: 56px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.dateOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
}

.dateDay {
  font-size: 24px;
}

.dateWeekday {
  font-size: 14px;
}

.menu {
  position: absolute;
  width: 80%;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 99999;
  background: #514bc0;
  transition: all 0.14s linear;
  padding-top: 56px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1), 0 0 15px 0 rgba(0, 0, 0, 0.2);
}

.menuOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.menuLink {
  height: 48px;
  line-height: 48px;
  vertical-align: middle;
  padding: 0 16px;
  font-size: 14px;
  /* cursor: pointer; */
}

.menuLink:hover {
  background: rgba(0, 0, 0, 0.1);
}
