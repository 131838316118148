.tags {
  display: flex;
  flex-wrap: wrap;
}

.tagsInputItem {
  display: inline-block;
  background: var(--ssColorBlackAlpha05);
  border-radius: 9999px;
  height: 24px;
  line-height: 24px;
  vertical-align: middle;
  font-size: 12px;
  padding: 0 8px 0 16px;
  margin-right: 8px;
  margin-top: 8px;
  cursor: pointer;

  display: inline-flex;
}

.tagsInputItem {
  color: var(--ssColorGray03);
  background: var(--ssColorGray00);
}

.tagsInputItem:hover {
  color: var(--ssColorBlue);
  background: var(--ssColorGray01);
}

.tagsInputItemDelete {
  width: 1em;
  height: 1em;

  margin-left: 4px;

  flex: none;
  order: 1;
  align-self: center;

  transition: fill 0.2s;
  pointer-events: auto;
  fill: var(--ssColorGray03);
}

.tagsInputItemDelete:hover {
  fill: var(--ssColorBlue);
}

.item {
  color: var(--ssColorGray03);
}

.item:hover {
  color: var(--ssColorGray06);
  background: var(--ssColorBlackAlpha05);
}

.item:active {
  transition: all 0.14s linear;
  color: var(--ssColorGray06);
  background: var(--ssColorBlackAlpha08);
}
