@import url(https://fonts.googleapis.com/css?family=Roboto);
:root {
  --ssColorWhite: #ffffff;
  --ssColorGray00: hsla(240, 10%, 95%, 1);
  --ssColorGray01: hsla(240, 10%, 90%, 1);
  --ssColorGray02: hsla(240, 10%, 85%, 1);
  --ssColorGray03: #555555;
  --ssColorGray06: #141414;
  --ssColorBlack: #000000;

  --ssColorBlackAlpha05: hsla(240, 100%, 10%, 0.05);
  --ssColorBlackAlpha08: hsla(240, 100%, 10%, 0.08);

  --ssColorPink: #e80a89;
  --ssColorRed: #de3636;
  --ssColorOrange: #f96816;
  --ssColorOrangeLight: #ff7b39;
  --ssColorYellow: #fff59d;
  --ssColorYellowDark: #fff066;
  --ssColorGreenLight: #86ce21;
  --ssColorGreen: #23aa5a;
  --ssColorLightBlue: #2196f3;
  --ssColorBlue: #1565c0;
  --ssColorBlueDark: #135cae;
}

:root {
  /* spacing */
  --ssLayoutSpacingXXSmall: 4px;
  --ssLayoutSpacingXSmall: 8px;
  --ssLayoutSpacingSmall: 12px;
  --ssLayoutSpacingMedium: 16px;
  --ssLayoutSpacingLarge: 24px;
  --ssLayoutSpacingXLarge: 48px;
  --ssLayoutSpacingXXLarge: 64px;
}

:root {
  /* --ssTypeFamilySans: 'Trebuchet MS', sans-serif; */
  /* --ssTypeFamilySans: 'Tahoma', sans-serif; */
  --ssFontFamilySans: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica,
    'Apple Color Emoji', Arial, sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
  --ssFontFamilySerif: sans-serif;
  --ssFontFamilyMonospace: monospace;

  /* font size */
  --ssTypeFontSizeGigantic: 60px;
  --ssTypeFontSizeJumbo: 48px;
  --ssTypeFontSizeXXLarge: 36px;
  --ssTypeFontSizeXLarge: 30px;
  --ssTypeFontSizeLarge: 24px;
  --ssTypeFontSizeMedium: 18px;
  --ssTypeFontSizeSmall: 14px;
  --ssTypeFontSizeXSmall: 12px;

  /* letter spacing */
  --ssTypeLetterSpacingGigantic: -1px;
  --ssTypeLetterSpacingJumbo: -0.72px;
  --ssTypeLetterSpacingXXLarge: -0.54px;
  --ssTypeLetterSpacingXLarge: -0.45px;
  --ssTypeLetterSpacingLarge: 0.36px;
  --ssTypeLetterSpacingMedium: 0;
  --ssTypeLetterSpacingSmall: 0;
  --ssTypeLetterSpacingXSmall: 0;

  /* font weight */
  --ssTypeFontWeightBold: 700;
  --ssTypeFontWeightMedium: 500;
  --ssTypeFontWeightBook: 400;
  --ssTypeFontWeightLight: 300;
  --ssTypeFontWeightXLight: 200;

  /* line height */
  --ssTypeLineHeightTight: 20px;
  --ssTypeLineHeightStandard: 24px;
  --ssTypeLineHeightExtra: 32px;
  --ssTypeLineHeightHuge: 40px;
}

:root {
  /* motion */
  --ssMotionSpeedXFast: 100ms;
  --ssMotionSpeedFast: 200ms;
  --ssMotionSpeedNormal: 300ms;
  --ssMotionSpeedSlow: 400ms;
  --ssMotionSpeedXSlow: 500ms;
}

:root {
  /* shadows */
  --ssShadow00: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  --ssFocusColor: hsla(212, 80%, 42%, 0.6);
  --ssFocusShadow: 0 0 0px 4px var(--ssFocusColor);
  --ssFocusShadowInput: 0 0 0px 1px var(--ssColorBlue);
}



*,
*::before,
*::after {
  box-sizing: border-box;
}

/* html {
  font-size: 62.5%;
} */

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-family: var(--ssFontFamilySans);
  overflow-x: hidden;
}

input,
select,
textarea {
  font-family: inherit;
}

button {
  outline: none;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

h2 {
  font-size: 20px;
  font-weight: normal;
}

h4 {
  font-size: 16px;
  font-weight: normal;
}

hr {
  height: 1px;
  margin: 8px 0;
  border: 0;
  background: var(--ssColorGray00);
}

label {
  cursor: inherit;
}

*::-webkit-scrollbar {
  position: absolute;
  -webkit-appearance: none;
  width: 8px;
}
/* *::-webkit-scrollbar-track {
} */
*::-webkit-scrollbar-thumb {
  background-clip: padding-box !important;
  border-radius: 4px;
  color: #fff;
}
*::-webkit-scrollbar-track {
  background: #f3f3f3;
  /* box-shadow: inset 0 -4px 0 0, inset 0 4px 0 0; */
}
*::-webkit-scrollbar-thumb {
  background: rgba(40, 40, 40, 0.4);
  /* box-shadow: inset 0 -2px, inset 0 -3px, inset 0 2px, inset 0 3px; */
  min-height: 36px;
}
*::-webkit-scrollbar-corner {
  background: #fff;
}

.app {
  display: flex;

  height: 100vh;
  overflow: hidden;

  background-size: cover;
  background-position: bottom center;
  background-attachment: fixed;

  -webkit-transition: background-image 0.05s ease-in;

  transition: background-image 0.05s ease-in;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 40px;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;
}

.flex {
  display: flex;
}

.flex.flex--center {
  align-items: center;
}

.flex.flex--between {
  justify-content: space-between;
}

.flex.flex--column {
  flex-direction: column;
}

.header-input {
  padding: 0 16px;
  border: 0;
  outline: 0;
  height: 32px;
  border-radius: 24px;
  background: var(--ssColorGray00);
}

.header-select {
  padding: 0 16px;
  border: 0;
  outline: 0;
  height: 32px;
  border-radius: 24px;
  border: 1px solid var(--ssColorGray01);
}

[data-tooltip]::after {
  content: attr(data-tooltip);

  position: absolute;
  top: 0;
  left: 100%;
  bottom: 0;
  z-index: 999999999;

  margin: auto;
  padding: 0px 8px;

  height: 22px;
  line-height: 22px;

  font-size: 14px;

  color: var(--ssColorWhite);
  background: var(--ssColorBlue);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.14s;
  transition: all 0.14s;

  pointer-events: none;
}

[data-tooltip]:hover::after {
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
  -webkit-transform: translateX(4px);
          transform: translateX(4px);
}

.dashboard {
  display: flex;
  flex-grow: 1;
  z-index: 100;
}

.content {
  position: relative;
  width: 100%;
  background: var(--ssColorWhite);

  -webkit-transition: all 0.21s;

  transition: all 0.21s;
  flex: none;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1), 0 0 15px 0 rgba(0, 0, 0, 0.2);

  overflow-y: auto;
  flex: auto;
}

.content:empty {
  display: none;
}

.page {
  min-height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.page__placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  color: #555;
  text-align: center;
}

.form {
  position: relative;
}

/* overflow */

.breakWord {
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  word-wrap: break-word;
}

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Spinner_overlay__1KQ2P {
  position: absolute;
  top: 0;
  left: 0;
  /* right: 0; */
  /* bottom: 0; */
  z-index: 99999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  background: rgba(255, 255, 255, 0.4);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.Spinner_spinner__2kvFZ {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 40px;
  height: 40px;
  background-color: #673ab7;
  /* background-color: #F06292; */

  /* margin: 100px auto; */
  -webkit-animation: Spinner_sk-rotateplane__3Gb8u 1.2s infinite ease-in-out;
          animation: Spinner_sk-rotateplane__3Gb8u 1.2s infinite ease-in-out;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

@-webkit-keyframes Spinner_sk-rotateplane__3Gb8u {
  0% {
    -webkit-transform: perspective(120px);
            transform: perspective(120px);
    background-color: yellow;
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
            transform: perspective(120px) rotateY(180deg);
    background-color: #673ab7;
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
            transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    background-color: #f06292;
  }
}

@keyframes Spinner_sk-rotateplane__3Gb8u {
  0% {
    -webkit-transform: perspective(120px);
            transform: perspective(120px);
    background-color: yellow;
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
            transform: perspective(120px) rotateY(180deg);
    background-color: #673ab7;
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
            transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    background-color: #f06292;
  }
}

.box--padding-1 {
  padding: 4px;
}

.box--padding-x-1 {
  padding-left: 4px;
  padding-right: 4px;
}

.box--padding-y-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.box--padding-2 {
  padding: 8px;
}

.box--padding-x-2 {
  padding-left: 8px;
  padding-right: 8px;
}

.box--padding-y-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.box--padding-3 {
  padding: 12px;
}

.box--padding-x-3 {
  padding-left: 12px;
  padding-right: 12px;
}

.box--padding-y-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.box--padding-4 {
  padding: 16px;
}

.box--padding-x-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.box--padding-y-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.box--padding-5 {
  padding: 20px;
}

.box--padding-x-5 {
  padding-left: 20px;
  padding-right: 20px;
}

.box--padding-y-5 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.box--padding-6 {
  padding: 24px;
}

.box--padding-x-6 {
  padding-left: 24px;
  padding-right: 24px;
}

.box--padding-y-6 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.box--margin-top-1 {
  margin-top: 4px;
}

.box--margin-top-2 {
  margin-top: 8px;
}

.box--margin-top-3 {
  margin-top: 12px;
}

.box--margin-top-4 {
  margin-top: 16px;
}

.box--margin-top-5 {
  margin-top: 20px;
}

.box--margin-top-6 {
  margin-top: 24px;
}

.box--margin-top-7 {
  margin-top: 28px;
}

.box--margin-top-8 {
  margin-top: 32px;
}

.box--margin-top-9 {
  margin-top: 36px;
}

.box--margin-top-10 {
  margin-top: 40px;
}

.box--margin-top-11 {
  margin-top: 44px;
}

.box--margin-top-12 {
  margin-top: 48px;
}

.Heading_heading__27mM_ {
  font-weight: 500;
  color: var(--ssColorGray03);
}

.Heading_xs__66N-S {
  font-size: 14px;
}
.Heading_sm__1IoUr {
  font-size: 16px;
}
.Heading_md__1hRBf {
  font-size: 18px;
}
.Heading_lg__19ie0 {
  font-size: 24px;
}
.Heading_xl__1reZ8 {
  font-size: 32px;
}

/* leading */

.Typography_lineHeight__N797N {
  line-height: 1.4;
}

/* alignment */
.Typography_left__1m_bZ {
  text-align: left;
}

.Typography_right__1FAuc {
  text-align: right;
}

.Typography_center__3C3aQ {
  text-align: center;
}

.Typography_justify__2ajQw {
  text-align: justify;
}

/* styles */

.Typography_bold__2iGIb {
  font-weight: bold;
}

.Typography_italic__22Gd3 {
  font-style: italic;
}

/* overflow */

.Typography_overflow__2zaxl {
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  /* word-wrap: break-word; */
  word-break: break-all;
}

.Typography_truncate__2dVBR {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Text_xs__3PRSt {
  font-size: 12px;
}
.Text_sm__1aiNk {
  font-size: 14px;
}
.Text_md__15DD2 {
  font-size: 16px;
}
.Text_lg__2fjim {
  font-size: 20px;
}
.Text_xl__duS9u {
  font-size: 24px;
}

.Layout_relative__2AdOI {
  position: relative;
}

.Layout_sticky__y_DEF {
  position: -webkit-sticky;
  position: sticky;
  z-index: 100000;
}

.Layout_fixed__1C1QC {
  position: fixed;
}

.Notification_notifications__1KITM {
  position: fixed;
  /* right: 80px; */
  /* bottom: 40px; */
  /* left: 0; */
  right: 48px;
  bottom: 40px;
  z-index: 99999999999;

  /* width: 50%; */
  /* padding: 40px; */

  margin: 0 auto;

  /* display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
  align-items: flex-end; */
}

.Notification_notification__3miU7 {
  width: 320px;
  /* background: var(--ssColorBlackAlpha08); */
  background: #fff;
  /* padding: 24px; */
  border-radius: 4px;
  margin-top: 8px;
  -webkit-animation: Notification_appear__PNS-k 0.14s;
          animation: Notification_appear__PNS-k 0.14s;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

@-webkit-keyframes Notification_appear__PNS-k {
  0% {
    opacity: 0;
    -webkit-transform: translateY(16px);
            transform: translateY(16px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes Notification_appear__PNS-k {
  0% {
    opacity: 0;
    -webkit-transform: translateY(16px);
            transform: translateY(16px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.Desktop_desktop__Zs2pe {
  width: 100%;
  display: flex;
  align-items: center;
}

.Desktop_apps__Z4QhG {
  width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  /* align-content: center; */
  justify-content: center;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: auto;

  padding: 0 48px;
}

.Desktop_app__item__E56yo {
  color: #000;
  color: initial;
  margin: 16px;
  text-align: center;
}

.Desktop_app__icon__1D_AJ {
  display: block;
  flex: none;
  width: 160px;
  height: 160px;
  line-height: 160px;

  border-radius: 20px;
  background: var(--ssColorWhite);
  cursor: pointer;

  text-align: center;
  vertical-align: middle;
  -webkit-transition: all 0.14s;
  transition: all 0.14s;

  font-size: 100px;
  font-family: 'Trebuchet MS', sans-serif;
  text-transform: uppercase;

  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.2);
}

.Desktop_app__item__E56yo:active .Desktop_app__icon__1D_AJ {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
  /* box-shadow: 0 0 6px 0 rgba(0,0,0,0.1), 0 5px 15px 0 rgba(0,0,0,0.2); */
}

.Desktop_app__icon__1D_AJ:hover {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  /* transform: translateY(-24px); */
}

.Desktop_app__icon__1D_AJ * {
  pointer-events: none;
}

.Desktop_app__item__E56yo.Desktop_dragging__1arC7 {
  z-index: 9999999999;
}

.Desktop_app__item__E56yo.Desktop_dragging__1arC7 .Desktop_app__icon__1D_AJ {
  opacity: 0.9;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1), 0 20px 60px 0 rgba(0, 0, 0, 0.2);
}

.Desktop_app__icon__1D_AJ.Desktop_new__10cv9 {
  border: 2px dashed #fff;
  background: rgba(255, 255, 255, 0.2);
}

.Desktop_app__name__3Xpzq {
  margin-top: 24px;
  color: var(--ssColorWhite);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  cursor: pointer;

  background: rgba(0, 0, 0, 0.14);
  border-radius: 24px;
  display: inline-block;
  padding: 4px 12px;
}

.Desktop_apps__thrash__3ulwh {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999999;
  margin: auto;
  height: 60px;
  line-height: 60px;
  vertical-align: middle;
  text-align: center;
  color: var(--ssColorWhite);
  /* background: rgba(255, 255, 255, 0.5); */
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  -webkit-transition: all 0.14s ease-out;
  transition: all 0.14s ease-out;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;
  /* font-size: 14px; */

  cursor: not-allowed;
}

.Desktop_apps__thrash__3ulwh.Desktop_show__1Yeea {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.Desktop_apps__thrash__3ulwh.Desktop_show__1Yeea:hover {
  background: var(--ssColorBlue);
}

.Modal_overlay__1p8qA {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.8);
}

.Modal_overlay__1p8qA > * {
  outline: 0;
  margin: 0 auto !important;
}

.Modal_popup__1-j7e {
  width: 600px;
  height: 600px;
  background: #fff;
  text-align: center;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal_popover__1gksc {
  position: absolute;
  z-index: 99999;
  width: 200px;
  height: 240px;
  background: #fff;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
}

.SpringSlider_slider__133N2 {
  overflow: hidden;
}

.SpringSlider_slides__2R5LA {
  display: flex;
}

.SpringSlider_slides__2R5LA > * {
  flex: none;
  width: 100%;
}

.Popup_popup__1-JDW {
  position: relative;
  margin: 40px 0;
}

.Popup_header__1rg1h {
  position: relative;
  color: var(--ssColorWhite);
  text-align: center;
  font-size: 32px;
  margin-bottom: 24px;
}

.Popup_close__24njk {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 0.4;
  -webkit-transition: opacity 0.14s ease-in;
  transition: opacity 0.14s ease-in;
}

.Popup_close__24njk:hover {
  opacity: 1;
}

.Popup_content__1VciX {
  position: relative;
  width: 600px;
  min-height: 300px;
  background: var(--ssColorWhite);
  border-radius: 4px;
}

.Popup_content__1VciX.Popup_content--nopadding__B8TH0 {
  padding: 0;
}

.Popup_contentHeader__N44Wm {
  position: relative;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}

.Popup_tabs__10aai {
  position: absolute;
  top: 0;
  left: -100px;

  list-style: none;
  margin: 0;
  padding: 0;
}

.Popup_button__YZS1U {
  width: 80px;
  height: 80px;
  /* line-height: 80px; */
  margin-bottom: 8px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  color: var(--ssColorWhite);
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  opacity: 0.4;
  outline: 0;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;
}

.Popup_button__YZS1U:hover {
  opacity: 0.6;
}

.Popup_button__YZS1U.Popup_buttonActive__1ydXQ {
  opacity: 1;
}

.Popup_buttonTitle__3L7iK {
  font-size: 12px;
  margin-top: 8px;
}

.Input_base__cigG_ {
  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  color: var(--ssColorBlack);
  background: var(--ssColorWhite);
  border: 1px solid var(--ssColorGray01);
  outline-color: var(--ssColorBlue);
  font-size: 14px;
  -webkit-transition: all 0.14s;
  transition: all 0.14s;
}

.Input_base__cigG_:hover {
  border-color: var(--ssColorGray02);
}

.Input_base__cigG_:focus {
  outline: 0;
  background: var(--ssColorWhite);
  box-shadow: var(--ssFocusShadowInput);
  border-color: var(--ssColorBlue);

  /* border-color: transparent; */
  /* box-shadow: var(--ssFocusShadow); */
}

.Input_base__cigG_:disabled {
  pointer-events: none;
  background: var(--ssColorGray00);
  border: 1px solid var(--ssColorGray00);
}

/* .base.selected,
.base:not(:placeholder-shown):not(:focus) {
  background: var(--ssColorWhite);
  border: 1px solid var(--ssColorGray01);
  color: #000;
} */

.Input_input__3vycb {
  height: 40px;
  padding: 0 16px;
}

.Input_textarea__1jfae {
  display: block;

  font-family: inherit;

  line-height: 24px;
  padding: 8px 16px;

  resize: vertical;
}

.Input_counter__2r6zs {
  position: absolute;
  right: 16px;
  bottom: 8px;
  color: var(--ssColorGray03);
  font-size: 14px;
  pointer-events: none;
}

.Input_select__icJP8 {
  height: 40px;
  padding: 0 12px;
  color: var(--ssColorGray03);
}

.Input_select__icJP8 option {
  background: var(--ssColorWhite);
}

.Input_select__icJP8 option:checked {
  font-weight: bold;
}

.Input_select__icJP8.Input_selected__3qBa8 {
  color: var(--ssColorBlack);
}

.Label_label__FUoYC {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */

  height: 40px;
  line-height: 40px;
  font-size: 14px;

  cursor: pointer;
}

.Label_label__FUoYC.Label_inline__23Cuh {
  display: inline-flex;
}

.Label_label__FUoYC.Label_reverse__2_dxN {
  flex-direction: row-reverse;
}

.Label_label__FUoYC.Label_justify__3lVdn {
  justify-content: space-between;
}

.Label_text__12YNL {
  margin-left: 8px;
}

.Label_reverse__2_dxN .Label_text__12YNL {
  margin-left: 0;
  margin-right: 8px;
}

.Toggle_tggl__3WsDs {
  position: relative;
  width: 40px;
  height: 20px;
  flex: none;
  /* cursor: pointer; */
  display: inline-block;
}

.Toggle_tggl__checkbox__1Ivvd {
  position: absolute;
  opacity: 0;
}

.Toggle_tggl__background__2gD85,
.Toggle_tggl__button__NWITZ {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.Toggle_tggl__background__2gD85 {
  width: 100%;
  height: 100%;
  background: var(--ssColorGray02);
  border-radius: 24px;
  cursor: pointer;
}

.Toggle_tggl__button__NWITZ {
  width: 50%;
  height: 100%;
  border-radius: 50%;
  background: var(--ssColorWhite);
  border: 2px solid transparent;
  box-sizing: border-box;
  background-clip: content-box;
  pointer-events: none;
}

.Toggle_tggl__checkbox__1Ivvd:checked ~ .Toggle_tggl__button__NWITZ {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.Toggle_tggl__checkbox__1Ivvd:checked ~ .Toggle_tggl__background__2gD85 {
  background: var(--ssColorBlue);
}

.Toggle_tggl__checkbox__1Ivvd:disabled ~ .Toggle_tggl__button__NWITZ,
.Toggle_tggl__checkbox__1Ivvd:disabled ~ .Toggle_tggl__background__2gD85 {
  pointer-events: none;
  cursor: default;
}

.Toggle_tggl__checkbox__1Ivvd:disabled ~ .Toggle_tggl__background__2gD85 {
  opacity: 0.5;
}

.Toggle_tggl__checkbox__1Ivvd:active ~ .Toggle_tggl__background__2gD85,
.Toggle_tggl__checkbox__1Ivvd:focus ~ .Toggle_tggl__background__2gD85 {
  box-shadow: var(--ssFocusShadow);
}

.Checkbox_checkbox__3vQWp {
  position: relative;
  display: inline-flex;
  outline: 0;
}

.Checkbox_input__1FIzP {
  /* display: none; */
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.Checkbox_label__5mAfG {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid var(--ssColorGray02);
  -webkit-transition: all 0.14s;
  transition: all 0.14s;
  cursor: pointer;
  outline: 0;
}

.Checkbox_label__5mAfG:hover {
  border-color: var(--ssColorBlue);
}

.Checkbox_label__5mAfG:active,
.Checkbox_input__1FIzP:focus ~ .Checkbox_label__5mAfG {
  /* border-color: var(--ssColorBlue); */
  box-shadow: var(--ssFocusShadow);
  outline: 0;
}

.Checkbox_label__5mAfG svg {
  display: block;
  fill: #fafafa;
  stroke-width: 3px;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 0.14s ease-in;
  transition: all 0.14s ease-in;
}

.Checkbox_input__1FIzP:checked + .Checkbox_label__5mAfG {
  border-color: var(--ssColorBlue);
}

.Checkbox_input__1FIzP:checked + .Checkbox_label__5mAfG svg {
  -webkit-transform: scale(1);
          transform: scale(1);
  background: var(--ssColorBlue);
}

.Checkbox_input__1FIzP[type='radio'] + .Checkbox_label__5mAfG,
.Checkbox_label__5mAfG.Checkbox_radio__WjyYU {
  border-radius: 50%;
  position: relative;
}

.Checkbox_input__1FIzP[type='radio'] + .Checkbox_label__5mAfG:after,
.Checkbox_label__5mAfG.Checkbox_radio__WjyYU:after {
  content: '';
  background: var(--ssColorBlue);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 0.14s ease-in;
  transition: all 0.14s ease-in;
}

.Checkbox_input__1FIzP[type='radio']:checked + .Checkbox_label__5mAfG:after {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.Checkbox_input__1FIzP:disabled + .Checkbox_label__5mAfG {
  background: var(--ssColorGray01);
  border-color: var(--ssColorGray01);
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

.Button_button__1qy1Z {
  position: relative;

  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  cursor: pointer;

  background: none;
  border: 0;

  box-sizing: border-box;
  padding: 0;
  margin: 0;

  font-family: inherit;
  text-align: center;
  vertical-align: middle;

  -webkit-transition: all 0.14s;

  transition: all 0.14s;
}

.Button_button__1qy1Z[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.Button_sm__2ElXS {
  min-width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;

  padding: 0 16px;
}
.Button_md__1SohT {
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;

  padding: 0 20px;
}
.Button_lg__1AWEF {
  min-width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;

  padding: 0 24px;
}

.Button_fill__2_CjV {
  width: 100%;
  height: 100%;
}

.Button_fit__1Mjl6 {
  height: 100%;
}

.Button_button__1qy1Z.Button_rounded__29ES6 {
  border-radius: 9999px;
}

.Button_button__1qy1Z.Button_block__1LxdW {
  width: 100%;
  display: block;
}

.Button_button__1qy1Z.Button_square__1btTc {
  padding: 0;
}

.Button_icon__K1U6E {
  margin-left: 8px;
}

.Themes_default__k8uPu {
  color: var(--ssColorGray03);
  background: var(--ssColorGray00);
  border-radius: 4px;
}

.Themes_default__k8uPu:focus {
  background: var(--ssColorWhite);
  box-shadow: 0 0 0px 2px var(--ssColorBlue);
}

.Themes_default__k8uPu:hover {
  color: var(--ssColorBlue);
  background: var(--ssColorGray01);
}

.Themes_default__k8uPu:focus:hover {
  color: var(--ssColorBlue);
  background: var(--ssColorWhite);
  /* box-shadow: 0 0 0px 2px var(--ssColorBlue); */
}

.Themes_default__k8uPu:active {
  background: var(--ssColorWhite);
}

.Themes_outline__cVEdN {
  border: 1px solid;
  background: var(--ssColorWhite);
  border-color: var(--ssColorGray01);
}

.Themes_outline__cVEdN:hover {
  color: var(--ssColorWhite);
  background: var(--ssColorBlue);
  border-color: var(--ssColorBlue);
}

.Themes_outline__cVEdN:active {
  color: var(--ssColorWhite);
  background: var(--ssColorBlueDark);
  border-color: var(--ssColorBlue);
}

.Themes_flat__CegwB {
  color: var(--ssColorGray03);
}

.Themes_flat__CegwB:focus,
.Themes_flat__CegwB:hover {
  color: var(--ssColorBlue);
  background: var(--ssColorBlackAlpha05);
}

.Themes_active__JBRJe .Themes_flat__CegwB,
.Themes_flat__CegwB:active {
  background: var(--ssColorBlackAlpha08);
}

.Themes_active__JBRJe > .Themes_flat__CegwB,
.Themes_flat__CegwB.Themes_active__JBRJe {
  color: var(--ssColorBlue);
}

.FormFiles_container__v-KCx {
  width: calc(100% + 8px); /* TODO find better solution */
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.FormFiles_column__1nSDF {
  position: relative;
  padding: 4px;
  width: 20%;
}

.FormFiles_img__1a3TY {
  border-radius: 4px;
}

.FormFiles_del__26F8R {
  position: absolute;
  z-index: 99999;
  top: -8px;
  right: -8px;
  width: 16px;
  height: 16px;
  line-height: 14px;
  text-align: center;
  vertical-align: middle;
  color: var(--ssColorWhite);
  background: var(--ssColorOrange);
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 15px;
}

.FormFiles_del__26F8R:hover,
.FormFiles_img__1a3TY:hover + .FormFiles_del__26F8R {
  opacity: 1;
}

.Image_image__1F1aX {
  display: block;
}

.Image_fill__1lxRr {
  height: 100%;
  width: 100%;
}

.Image_contain__1QNeS {
  /* composes: fill; */
  /* background-size: contain; */
  object-fit: contain;
}

.Image_cover__2PBct {
  /* composes: fill; */
  /* background-size: cover; */
  object-fit: cover;
}

.Shimmer_background__1Zzx2 {
  width: 100%;
  background: var(--ssColorGray00);
  background-image: -webkit-gradient(
    linear,
    left top, right top,
    from(var(--ssColorGray00)),
    color-stop(20%, var(--ssColorGray01)),
    color-stop(40%, var(--ssColorGray00)),
    to(var(--ssColorGray00))
  );
  background-image: -webkit-linear-gradient(
    left,
    var(--ssColorGray00) 0%,
    var(--ssColorGray01) 20%,
    var(--ssColorGray00) 40%,
    var(--ssColorGray00) 100%
  );
  background-image: linear-gradient(
    to right,
    var(--ssColorGray00) 0%,
    var(--ssColorGray01) 20%,
    var(--ssColorGray00) 40%,
    var(--ssColorGray00) 100%
  );
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  -webkit-animation: Shimmer_Shimmer__2wSKJ 1400ms linear infinite forwards;
          animation: Shimmer_Shimmer__2wSKJ 1400ms linear infinite forwards;
}
@-webkit-keyframes Shimmer_Shimmer__2wSKJ {
  0% {
    background-position: -100vw 0;
  }
  100% {
    background-position: 100vw 0;
  }
}
@keyframes Shimmer_Shimmer__2wSKJ {
  0% {
    background-position: -100vw 0;
  }
  100% {
    background-position: 100vw 0;
  }
}
.Shimmer_placeholder__299qq {
  color: #000;
  background: #000;
  mix-blend-mode: lighten;
  height: 100%;
}

.Square_square__2-hi9 {
  position: relative;
  padding-bottom: 100%;
}

.Square_content__JhItn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.input-place-suggestions {
  position: absolute;
  top: 100%;
  background-color: white;
  /* border: 1px solid #555555; */
  border: 0;
  width: 100%;
  z-index: 999999;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);
}

.suggestion-item {
  font-size: 14px;
  background-color: #ffffff;
  padding: 10px;
  color: #555555;
  cursor: pointer;
}

.suggestion-item:hover {
  color: var(--ssColorGray06);
  background: var(--ssColorBlackAlpha05);
}

.rdtPicker {
  font-size: 14px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  right: 0;
}

.rdtPicker table {
  border-collapse: collapse;
}

.rdtPicker .rdtDay {
  width: 32px;
  height: 32px;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: var(--ssColorBlue);
}

.ColorInput_relative__-aS6E {
  position: relative;
}

.ColorInput_preview__14_FW {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  /* width: 50%; */
  width: 40px;
  border: 1px solid transparent;
  box-sizing: border-box;
  background-clip: content-box;
  border-radius: 0 4px 4px 0;
  /* pointer-events: none; */

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

input:focus ~ .ColorInput_preview__14_FW,
.ColorInput_preview__14_FW:hover {
  background-color: var(--ssColorGray00);
}

.ColorInput_color__10pFi {
  width: 16px;
  height: 16px;
  border-radius: 50%;

  background: #fff;
}

.FormRow_group__3KMlI {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px 12px;
}

.FormRow_group__3KMlI.FormRow_block__1PGeC {
  display: block;
}

.FormRow_group__3KMlI.FormRow_block__1PGeC .FormRow_aside__2wNV8 {
  max-width: 100%;
  padding-top: 0;
  padding-bottom: 12px;
}

.FormRow_group__3KMlI + .FormRow_group__3KMlI {
  border-top: 1px solid var(--ssColorGray00);
}

.FormRow_aside__2wNV8 {
  flex: none;
  min-width: 30%;
  max-width: 50%;

  padding: 10px 12px;
}

.FormRow_label__3gxqj {
  font-size: 14px;
  font-weight: 500;
  color: var(--ssColorGray06);
}

.FormRow_descr__1M8we {
  font-size: 12px;
  color: var(--ssColorGray03);
  margin-bottom: 8px;
}

.FormRow_content__2XsGv {
  width: 100%;
  padding: 0 12px;
}

.FormFooter_footer__37obk {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-top: 1px solid;
  border-radius: 0 0 4px 4px;
  overflow: hidden;

  color: var(--ssColorGray03);
  background: var(--ssColorGray00);
  border-color: var(--ssColorGray02);
}

.Mask_mask__1Nzzr {
  overflow: hidden;
}

.Mask_square__3jsnO {
  border-radius: 0;
}

.Mask_rounded__13dnU {
  border-radius: 4px;
}

.Mask_circle__1VypF {
  border-radius: 50%;
}

.Touch_touch__3fQFS {
  cursor: pointer;
  -webkit-transition: all 0.14s;
  transition: all 0.14s;
}

.Touch_touch__3fQFS:hover > .Touch_touch__preview__3alBT,
.Touch_touch__3fQFS:hover > * {
  opacity: 0.8;
}

.Touch_touch__3fQFS:active {
  box-shadow: 0 0 0px 2px var(--ssColorBlue);
}

.Navbar_navbar__1CKMk {
  flex: none;
  width: 48px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0 24px 0;
  text-align: center;
}

.Navbar_navbar__1CKMk.Navbar_active__3v2pn {
  background: var(--ssColorWhite);
}

.Navbar_navbar__1CKMk.Navbar_lite__2O2g5 {
  background: transparent;
}

.Navbar_navbar__1CKMk.Navbar_right__2TKje {
  position: absolute;
  right: 0;
  -webkit-transition: background 0.14s;
  transition: background 0.14s;
}

.Navbar_navbarTop__149oo {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.Navbar_navbarBottom__39wgN {
  flex-grow: 0;
}

.App_app__1lCnJ {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', 'San Francisco', sans-serif;
  color: var(--ssColorWhite);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
}

.App_app__1lCnJ div::-webkit-scrollbar {
  display: none;
}

.App_statusbar__31O_M {
  position: absolute;
  top: 0;
  z-index: 999999;
  width: 100%;
  height: 24px;
  background: black;
  opacity: 0.1;
}

.App_surface__2pwg- {
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 100%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1), 0 0 15px 0 rgba(0, 0, 0, 0.2);
  background: #514bc0;
}

.App_navbar__2CNdE {
  height: 56px;
  margin-top: 24px;

  display: flex;
  align-items: center;
}

.App_navbar__title__3WIoz {
  width: 100%;
  padding: 0 8px;
  text-align: center;
  font-weight: bold;
}

.App_navbar__button__2rBG6 {
  flex: none;
  width: 56px;
  height: 56px;
  text-align: center;
  line-height: 56px;
  vertical-align: middle;
}

.App_navbar__button__2rBG6:hover {
  background: rgba(0, 0, 0, 0.1);
}

/*phone-only*/
@media (max-width: 599px) {
  .App_app__1lCnJ {
    position: fixed;
    z-index: 99999999999;
  }
  .App_statusbar__31O_M {
    display: none;
  }
  .App_navbar__2CNdE {
    padding-top: 0;
    height: 56px;
  }
}

.App_body__1LmV4 {
  width: 100%;
  height: 100%;
  background: #fff;
  padding-top: 80px;
  /* padding-bottom: 16px; */
  overflow-x: hidden;
  overflow-y: auto;
}

.App_body__1LmV4::-webkit-scrollbar {
  display: none;
}

.App_title3__3zRyH {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.0015em;

  color: #333333;
}

.App_subtitle1__1_8L9 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.0015em;
}

.App_subtitle2__1RJar {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.001em;

  color: #757575;
}

.App_date__UyJPL {
  width: 56px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.App_dateOverlay__2MScz {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
}

.App_dateDay__3QLt0 {
  font-size: 24px;
}

.App_dateWeekday__1IP1j {
  font-size: 14px;
}

.App_menu__3AKrD {
  position: absolute;
  width: 80%;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 99999;
  background: #514bc0;
  -webkit-transition: all 0.14s linear;
  transition: all 0.14s linear;
  padding-top: 56px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1), 0 0 15px 0 rgba(0, 0, 0, 0.2);
}

.App_menuOverlay__2JIJ9 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.App_menuLink__nUQeM {
  height: 48px;
  line-height: 48px;
  vertical-align: middle;
  padding: 0 16px;
  font-size: 14px;
  /* cursor: pointer; */
}

.App_menuLink__nUQeM:hover {
  background: rgba(0, 0, 0, 0.1);
}

.Tile_tile__pOrlU {
  position: relative;
  overflow: hidden;
}

.Tile_overlay__1Prsu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListItem_item__2YJdR {
  height: 72px;
  /* width: 100%; */
  display: flex;
  align-items: center;
  /* padding: 8px; */
  color: #000;
  font-size: 14px;
  padding: 0 16px;
}

.ListItem_item__2YJdR.ListItem_reverse__31nl1 {
  flex-direction: row-reverse;
}

.ListItem_item__2YJdR.ListItem_right__3zLVm {
  padding-right: 0;
  justify-content: space-between;
}

.ListItem_item__2YJdR + .ListItem_item__2YJdR {
  border-top: 1px solid rgba(40, 40, 40, 0.1);
}

.ListItem_preview__1HvPD {
  margin-right: 16px;
}

.ListItem_square__1rF8y {
  width: 56px;
  height: 56px;
  background: #c4c4c4;
  border-radius: 8px;
  overflow: hidden;
}

.ListItem_content__CM6ra {
  flex: 1 1;
}

.ListItem_title__1IDGV {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: bold;
  color: #111;
}

.ListItem_descr__281OM {
  font-size: 14px;
  color: #757575;
}

.ListItem_avatar__3_tVe {
  width: 56px;
  height: 56px;
  background: #c4c4c4;
  border-radius: 50%;
  overflow: hidden;
}

.Frontpage_whatsnext__ZKove {
  height: 96px;
  background: #fff;
  display: flex;

  padding: 16px;
}

.Frontpage_whatsnext__left__j2l4b {
  width: 56px;
  margin-right: 16px;
}

.Frontpage_slider__2nDTH {
  display: flex;
  background: #eee;
  padding: 12px;
}

.Frontpage_sliderItem__27gHJ {
  flex: none;
  width: 80%;
  padding: 4px;
}

.Frontpage_gridview__11m0c {
  display: flex;
  flex-wrap: wrap;
  background: #eee;
  padding: 12px;
}

.Frontpage_gridviewItem__1yeJh {
  width: 50%;
  padding: 4px;
}

.MenuLink_item__1YHK- {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;

  padding: 0 16px;

  font-size: 14px;
  border-radius: 20px 0 0 20px;

  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */

  -webkit-transition: all 0.14s linear;

  transition: all 0.14s linear;
}

.MenuLink_item__1YHK-:hover {
  -webkit-transition: all 0.04s linear;
  transition: all 0.04s linear;
}

.Themes_menuItem__3uhPM {
  color: var(--ssColorGray03);
}

.Themes_menuItem__3uhPM:hover,
.Themes_menuItem__3uhPM.Themes_hover__2dDzW {
  color: var(--ssColorGray06);
  background: var(--ssColorBlackAlpha05);
}

.Themes_menuItem__3uhPM.Themes_active__3aulr,
.Themes_menuItem__3uhPM:active {
  color: var(--ssColorBlue);
  background: var(--ssColorBlackAlpha08);
}

.Badge_badge__35WWb {
  display: inline-block;

  font-size: 8px;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  padding: 4px 6px;
  border-radius: 9999px;
}

.Badge_badge__35WWb.Badge_default__2N8sU {
  color: var(--ssColorWhite);
  background: var(--ssColorOrangeLight);
}

.Badge_badge__35WWb.Badge_middle__2rpC5 {
  vertical-align: middle;
}

.Badge_badge__35WWb.Badge_top__2f1bF {
  vertical-align: super;
}

.TableFirstColumn_tableLink__2xYMI {
  color: var(--ssColorBlue);
  display: inline-flex;
}

.TableFirstColumn_tableLink__2xYMI:hover {
  text-decoration: underline;
}

.Table_table__gtUlK {
  width: 100%;
}

.Table_header__2-Ibc {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9999999;
  background: #fff;
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.Table_body__gCnD- {
  overflow-y: auto;
  overflow-x: hidden;
}

.Table_sorting__1MVLR {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}

.Table_row__3QZ98 {
  display: flex;

  height: 48px;

  font-size: 12px;
  line-height: 1.4;

  border-top: 1px solid var(--ssColorGray00);
}

.Table_header__2-Ibc .Table_row__3QZ98 {
  border-top: none;
}

.Table_body__gCnD- .Table_row__3QZ98:hover {
  background: var(--ssColorBlackAlpha05);
}

.Table_row__3QZ98.Table_dragging__2IXSc {
  z-index: 999999;
  background: var(--ssColorBlackAlpha08);
  border: 0;
}

.Table_cell__3kAkx {
  flex: 1 1;
  display: flex;
  align-items: center;
  padding: 0 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Table_cell__3kAkx:first-child {
  padding-left: 24px;
}

.Table_cell__3kAkx:last-child {
  padding-right: 24px;
}

.Table_column__tqqaM {
  color: var(--ssColorGray03);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /* border-bottom: 1px solid transparent; */
}

.Table_column__tqqaM:hover {
  color: var(--ssColorGray06);
}

.Table_column__tqqaM[sorting]:after {
  margin-left: 4px;
}

.Table_column__tqqaM[sorting='0']:after {
  content: '↑';
}

.Table_column__tqqaM[sorting='1']:after {
  content: '↓';
}

.Table_tableIcon__1JfHW {
  width: 48px;
  text-align: center;
  color: var(--ssColorGray02);
  cursor: pointer;
}

.Table_tableIcon__1JfHW * {
  visibility: hidden;
}

.Table_tableIcon__1JfHW.Table_tableIconShow__2k-fx * {
  visibility: visible;
}

.Table_tableIcon__1JfHW:hover {
  color: var(--ssColorGray03);
}

.Table_row__3QZ98:hover .Table_tableIcon__1JfHW * {
  visibility: visible;
}

.CoverUpload_upload__yrpDQ {
  position: relative;
  height: 100%;
  background-size: cover;
}

.CoverUpload_overlay__3oxE2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  color: #fff;
  background: hsla(0, 0%, 0%, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.14s;
  transition: all 0.14s;
  cursor: pointer;
}

.CoverUpload_upload__yrpDQ:hover .CoverUpload_overlay__3oxE2 {
  visibility: visible;
  opacity: 1;
}

.ImageSlider_arrow__hqDvG {
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 100px;
  height: 100%;
  color: var(--ssColorWhite);
  border: 0;
  outline: 0;
  opacity: 0;
  -webkit-transition: opacity 0.14s;
  transition: opacity 0.14s;
  cursor: pointer;
}

.ImageSlider_arrow__hqDvG:hover {
  opacity: 1;
}

.ImageSlider_prev__2JNGm {
  left: 0;
  background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.ImageSlider_next__3UT6C {
  right: 0;
  background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.5)));
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}

.ImageSlider_img__3X8mo {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.SearchInput_search__RSayY {
  width: 100%;
  height: 32px;
  padding: 0 16px;
  border: 0;
  outline: 0;
  border-radius: 9999px;
  -webkit-transition: all 0.14s linear;
  transition: all 0.14s linear;
  background: var(--ssColorGray00);
}

.SearchInput_search__RSayY:hover {
  background: var(--ssColorGray01);
}

.SearchInput_search__RSayY:focus {
  background: var(--ssColorWhite);
  box-shadow: 0 0 0px 2px var(--ssColorBlue);
}

.table-placeholder {
  height: 100%;
  padding: 0 24px;
  display: flex;
  align-items: center;
  background: #fff;
}
.placeholder-circle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #000;
}
.placeholder-object {
  background: #000;
}

.Stats_stats__1xesD {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Stats_card__3Dxqh {
  height: 140px;

  padding: 16px 24px;
  padding-right: 40%;

  color: var(--ssColorGray06);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  overflow: hidden;
}

.Stats_card__3Dxqh:hover {
  border-color: var(--ssColorBlue);
}

.Stats_card__3Dxqh + .Stats_card__3Dxqh {
  border-top: 1px solid var(--ssColorGray01);
}

.Sidebar_sidebar__205Ji {
  display: flex;
  position: relative;
  background: var(--ssColorWhite);
}

.Sidebar_menu__nsMnF {
  width: 240px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.Sidebar_menu__nsMnF:empty {
  display: none;
}

.preview {
  position: relative;

  width: 416px;
  height: 100vh;
  flex: none;
  display: flex;
  align-items: center;

  -webkit-transition: all 0.5s;

  transition: all 0.5s;

  flex-grow: 1;
}

.preview.preview--hidden {
  width: 0;
  padding: 0;
}

.preview__resize {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  height: 100%;
  width: 16px;
  /* opacity: 0.5; */
  cursor: col-resize;
  cursor: e-resize;

  /* width: 48px; */
  -webkit-transition: all 0.14s;
  transition: all 0.14s;
  width: 8px;
  border-left: 2px solid transparent;
  /* border-right: 3px solid transparent; */
  /* background-clip: content-box;
  box-sizing: border-box; */
}

.preview__resize--right {
  left: auto;
  right: 0;
  border-left: 0;
  border-right: 2px solid transparent;
  cursor: default;
}

.preview__resize:hover {
  /* background-color: var(--ssColorBlue); */
  border-color: #2196f3;
  /* transition: all 5s; */
  -webkit-transition-delay: 500ms;
          transition-delay: 500ms;
}

.preview__center {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  /* top: 0; */
  /* bottom: 0; */
  /* margin: auto; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview__device {
  width: 100%;
  display: flex;
  align-items: center;
}

.preview .preview__device {
  -webkit-transition: all 0.5s !important;
  transition: all 0.5s !important;
  opacity: 1;
}

.preview--hidden .preview__device {
  opacity: 0;
}

.preview__device {
  position: relative;
  width: 320px;
  height: 570px;
  flex: none;
  margin: 0 auto;
  padding: 0;

  color: var(--ssColorWhite);
  background: var(--ssColorBlack);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.14s ease-out;
  transition: all 0.14s ease-out;

  overflow: hidden;
}

.SortableMenu_container__2Aa_o {
  margin: 0 -24px;
}

.SortableMenu_container__2Aa_o.SortableMenu_sorting__1t__Q {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}

.SortableMenu_item__3syQ- {
  position: relative;
  z-index: 99999;
  height: 40px;
  line-height: 40px;
  font-size: 14px;

  display: flex;
  justify-content: space-between;

  padding-left: 24px;
  padding-right: 12px;

  cursor: move;
}

.SortableMenu_icon__3y3oX {
  flex: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  text-align: center;
  color: var(--ssColorGray02);
  cursor: pointer;
}

.SortableMenu_icon__3y3oX:hover {
  color: var(--ssColorBlack);
}

.Banner_banner__1ihq5 {
  position: relative;
  width: 100%;
  margin: 16px 0;
}

.Banner_image__2Qdp4 {
  width: 100%;
  height: 128px;
  object-fit: cover;
  border-radius: 4px;

  cursor: pointer;
  -webkit-transition: all 0.14s;
  transition: all 0.14s;
}

.Banner_image__2Qdp4:hover {
  opacity: 0.8;
}

.sortable-item {
  position: relative;
  z-index: 100;
  background: #fff;
}

.sortable-handle {
  /* width: 24px; */
  height: 24px;
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
  color: #aaa;
  font-size: 24px;
  text-align: right;
  line-height: 6px;
  overflow: hidden;
}

.sortable-handle div {
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px);
}

.rightside {
  position: relative;
  height: 100vh;
  width: 360px;
  overflow-y: auto;
  background: #fff;
}

.app-icons {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-grow: 1;
  z-index: 1000;
}

.upload-button {
  z-index: 1000;
}

.app-icon {
  display: block;
  width: 120px;
  height: 120px;
  /* background: rgba(0,0,0,.2); */
  border-radius: 24px;
  object-fit: cover;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.28);
  -webkit-transition: all 0.14s linear;
  transition: all 0.14s linear;
}

.app-icon:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.app-icon.app-icon--64 {
  width: 80px;
  height: 80px;
  border-radius: 16px;
}

.app-icon.app-icon--96 {
  width: 96px;
  height: 96px;
  border-radius: 16px;
}

.app-form {
  position: relative;
  min-height: 240px;
  text-align: center;
}

.app-cover {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* margin: -24px; */
  /* padding: 24px; */
  height: 300px;
  /* margin: -24px -24px 0 -24px; */
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  cursor: pointer;
}

.app-cover::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: var(--ssColorWhite);
  opacity: 0;
  -webkit-transition: all 0.14s linear;
  transition: all 0.14s linear;
}

.app-cover:hover::after {
  opacity: 0.4;
}

.app-cover:active::after {
  opacity: 0.2;
}

.app-icon:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
}

.app-input {
  border: 0;
  outline: 0;
  font-size: 24px;
  text-align: center;
  width: 100%;
}

.app-input.app-input--small {
  font-size: 12px;
  letter-spacing: 1px;
  color: var(--ssColorGray03);
  text-transform: uppercase;
}

textarea.app-textarea {
  color: var(--ssColorGray03);
  font-size: 16px;
  border: 0;
  text-align: center;
}

textarea.app-textarea:focus {
  box-shadow: none;
}

.System_system___0eRC {
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.System_system___0eRC {
  width: 600px;
  margin: 0 auto;
}

.System_color__2jM0B {
  width: 100px;
  height: 100px;
  border-radius: 16px;
}

.System_color__2jM0B.System_gray00__3nL5H {
  background: var(--ssColorGray00);
}
.System_color__2jM0B.System_gray01__1sale {
  background: var(--ssColorGray01);
}
.System_color__2jM0B.System_gray02__21_tV {
  background: var(--ssColorGray02);
}
.System_color__2jM0B.System_gray03__1SFvV {
  background: var(--ssColorGray03);
}
.System_color__2jM0B.System_gray04__3RtJb {
  background: var(--ssColorGray04);
}
.System_color__2jM0B.System_gray05__2vsSE {
  background: var(--ssColorGray05);
}
.System_color__2jM0B.System_gray06__1IJWq {
  background: var(--ssColorGray06);
}

.Tooltip_tooltip__2IphH {
  padding: 4px 8px;
  font-size: 12px;
  color: var(--ssColorWhite);
  background: var(--ssColorGray03);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  white-space: nowrap;
}

.Tooltip_ghost__2cRSP {
  position: fixed;
  z-index: 999999999;
  pointer-events: none;
}

.Tooltip_align__1BfWW {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Tooltip_ttooltip__PKq0t {
  position: absolute;
  top: 0;
  left: 100%;
  bottom: 0;
  z-index: 999999999;

  margin: auto;
  padding: 0px 8px;

  height: 22px;
  line-height: 22px;

  font-size: 14px;

  color: var(--ssColorWhite);
  background: var(--ssColorBlue);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.14s;
  transition: all 0.14s;

  pointer-events: none;
}

.TagsInput_tags__33Uw0 {
  display: flex;
  flex-wrap: wrap;
}

.TagsInput_tagsInputItem__2SI2d {
  display: inline-block;
  background: var(--ssColorBlackAlpha05);
  border-radius: 9999px;
  height: 24px;
  line-height: 24px;
  vertical-align: middle;
  font-size: 12px;
  padding: 0 8px 0 16px;
  margin-right: 8px;
  margin-top: 8px;
  cursor: pointer;

  display: inline-flex;
}

.TagsInput_tagsInputItem__2SI2d {
  color: var(--ssColorGray03);
  background: var(--ssColorGray00);
}

.TagsInput_tagsInputItem__2SI2d:hover {
  color: var(--ssColorBlue);
  background: var(--ssColorGray01);
}

.TagsInput_tagsInputItemDelete__2MPeP {
  width: 1em;
  height: 1em;

  margin-left: 4px;

  flex: none;
  order: 1;
  align-self: center;

  -webkit-transition: fill 0.2s;

  transition: fill 0.2s;
  pointer-events: auto;
  fill: var(--ssColorGray03);
}

.TagsInput_tagsInputItemDelete__2MPeP:hover {
  fill: var(--ssColorBlue);
}

.TagsInput_item__3nL9f {
  color: var(--ssColorGray03);
}

.TagsInput_item__3nL9f:hover {
  color: var(--ssColorGray06);
  background: var(--ssColorBlackAlpha05);
}

.TagsInput_item__3nL9f:active {
  -webkit-transition: all 0.14s linear;
  transition: all 0.14s linear;
  color: var(--ssColorGray06);
  background: var(--ssColorBlackAlpha08);
}

.Login_page__3Yq6I {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.Login_header__JBxcZ {
  /* position: relative; */
  color: var(--ssColorWhite);
  text-align: center;
  font-size: 32px;
  margin-bottom: 24px;
}

.Login_headerLink__16InI,
.Login_headerSpan__266HS {
  color: var(--ssColorWhite);
  opacity: 0.5;
}

.Login_headerLink__16InI.Login_active__2CTpB {
  opacity: 1;
  text-decoration: underline;
}

.Login_modal__3MW5P {
  position: relative;
  width: 400px;
  background: var(--ssColorWhite);
  border-radius: 4px;
  padding: 40px 24px;
  overflow: hidden;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);

  text-align: center;
}

.Login_message__3zDlH {
  margin-bottom: 24px;
  color: #e91e63;
}

/* .form {
  border: 1px solid var(--ssColorGray01);
  border-radius: 4px;
  overflow: hidden;
} */

.Login_input__3l9cJ {
  position: relative;
  width: 100%;
  height: 60px;
  padding: 0 16px;
  background: #fafafa;
  border: 0;
  /* border-radius: 4px; */
  /* outline-color: var(--ssColorBlue); */
  font-size: 24px;

  text-align: center;

  outline: 0;

  border: 1px solid var(--ssColorGray01);
  margin-top: -1px;
}

.Login_input__3l9cJ:first-child {
  border-radius: 4px 4px 0 0;
}

.Login_input__3l9cJ:hover {
  border-color: var(--ssColorGray02);
  z-index: 1;
}

.Login_input__3l9cJ:focus {
  border-color: var(--ssColorBlue);
  z-index: 1;
}

/* .input + .input {
  margin-top: 8px;
  border-top: 1px solid var(--ssColorGray01);
} */

.Login_button__28rgh {
  width: 100%;
  height: 60px;
  border: 0;
  outline: 0;
  cursor: pointer;
  color: var(--ssColorWhite);
  background: var(--ssColorGray02);
  font-size: 24px;
  text-transform: capitalize;
  /* margin-bottom: 16px; */

  border-radius: 0 0 4px 4px;
  -webkit-transition: all 0.14s;
  transition: all 0.14s;
  pointer-events: none;
}

.Login_button__28rgh.Login_valid__2glGl {
  background: var(--ssColorBlue);
  pointer-events: auto;
}

.Login_button__28rgh:hover {
  background: var(--ssColorBlueDark);
}

.Login_reset__1b0k8 {
  display: inline-block;
  margin-top: 40px;
  color: var(--ssColorBlue);
}

.Login_reset__1b0k8:hover {
  text-decoration: underline;
  color: var(--ssColorBlueDark);
}

.Login_text__3UtKr {
  color: var(--ssColorGray03);
  font-size: 14px;
  line-height: 1.4;
  margin-top: 40px;
}

.Login_link__3fghe {
  color: var(--ssColorBlue);
}

.Login_link__3fghe:hover {
  text-decoration: underline;
  color: var(--ssColorBlueDark);
}

