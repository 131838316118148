/* leading */

.lineHeight {
  line-height: 1.4;
}

/* alignment */
.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.justify {
  text-align: justify;
}

/* styles */

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

/* overflow */

.overflow {
  hyphens: auto;
  /* word-wrap: break-word; */
  word-break: break-all;
}

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
