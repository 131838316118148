.table-placeholder {
  height: 100%;
  padding: 0 24px;
  display: flex;
  align-items: center;
  background: #fff;
}
.placeholder-circle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #000;
}
.placeholder-object {
  background: #000;
}
