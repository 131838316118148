.app-icons {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-grow: 1;
  z-index: 1000;
}

.upload-button {
  z-index: 1000;
}

.app-icon {
  display: block;
  width: 120px;
  height: 120px;
  /* background: rgba(0,0,0,.2); */
  border-radius: 24px;
  object-fit: cover;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.28);
  transition: all 0.14s linear;
}

.app-icon:active {
  transform: scale(0.98);
}

.app-icon.app-icon--64 {
  width: 80px;
  height: 80px;
  border-radius: 16px;
}

.app-icon.app-icon--96 {
  width: 96px;
  height: 96px;
  border-radius: 16px;
}

.app-form {
  position: relative;
  min-height: 240px;
  text-align: center;
}

.app-cover {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* margin: -24px; */
  /* padding: 24px; */
  height: 300px;
  /* margin: -24px -24px 0 -24px; */
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  cursor: pointer;
}

.app-cover::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: var(--ssColorWhite);
  opacity: 0;
  transition: all 0.14s linear;
}

.app-cover:hover::after {
  opacity: 0.4;
}

.app-cover:active::after {
  opacity: 0.2;
}

.app-icon:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
}

.app-input {
  border: 0;
  outline: 0;
  font-size: 24px;
  text-align: center;
  width: 100%;
}

.app-input.app-input--small {
  font-size: 12px;
  letter-spacing: 1px;
  color: var(--ssColorGray03);
  text-transform: uppercase;
}

textarea.app-textarea {
  color: var(--ssColorGray03);
  font-size: 16px;
  border: 0;
  text-align: center;
}

textarea.app-textarea:focus {
  box-shadow: none;
}
