.desktop {
  width: 100%;
  display: flex;
  align-items: center;
}

.apps {
  width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  /* align-content: center; */
  justify-content: center;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: auto;

  padding: 0 48px;
}

.app__item {
  color: initial;
  margin: 16px;
  text-align: center;
}

.app__icon {
  display: block;
  flex: none;
  width: 160px;
  height: 160px;
  line-height: 160px;

  border-radius: 20px;
  background: var(--ssColorWhite);
  cursor: pointer;

  text-align: center;
  vertical-align: middle;
  transition: all 0.14s;

  font-size: 100px;
  font-family: 'Trebuchet MS', sans-serif;
  text-transform: uppercase;

  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.2);
}

.app__item:active .app__icon {
  transform: scale(0.98);
  /* box-shadow: 0 0 6px 0 rgba(0,0,0,0.1), 0 5px 15px 0 rgba(0,0,0,0.2); */
}

.app__icon:hover {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  /* transform: translateY(-24px); */
}

.app__icon * {
  pointer-events: none;
}

.app__item.dragging {
  z-index: 9999999999;
}

.app__item.dragging .app__icon {
  opacity: 0.9;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1), 0 20px 60px 0 rgba(0, 0, 0, 0.2);
}

.app__icon.new {
  border: 2px dashed #fff;
  background: rgba(255, 255, 255, 0.2);
}

.app__name {
  margin-top: 24px;
  color: var(--ssColorWhite);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  cursor: pointer;

  background: rgba(0, 0, 0, 0.14);
  border-radius: 24px;
  display: inline-block;
  padding: 4px 12px;
}

.apps__thrash {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999999;
  margin: auto;
  height: 60px;
  line-height: 60px;
  vertical-align: middle;
  text-align: center;
  color: var(--ssColorWhite);
  /* background: rgba(255, 255, 255, 0.5); */
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  transition: all 0.14s ease-out;
  transform: translateY(-100%);

  user-select: none;
  /* font-size: 14px; */

  cursor: not-allowed;
}

.apps__thrash.show {
  transform: translateY(0);
}

.apps__thrash.show:hover {
  background: var(--ssColorBlue);
}
