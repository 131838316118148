.whatsnext {
  height: 96px;
  background: #fff;
  display: flex;

  padding: 16px;
}

.whatsnext__left {
  width: 56px;
  margin-right: 16px;
}

.slider {
  display: flex;
  background: #eee;
  padding: 12px;
}

.sliderItem {
  flex: none;
  width: 80%;
  padding: 4px;
}

.gridview {
  display: flex;
  flex-wrap: wrap;
  background: #eee;
  padding: 12px;
}

.gridviewItem {
  width: 50%;
  padding: 4px;
}
