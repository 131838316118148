.rdtPicker {
  font-size: 14px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  right: 0;
}

.rdtPicker table {
  border-collapse: collapse;
}

.rdtPicker .rdtDay {
  width: 32px;
  height: 32px;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: var(--ssColorBlue);
}
