.sortable-item {
  position: relative;
  z-index: 100;
  background: #fff;
}

.sortable-handle {
  /* width: 24px; */
  height: 24px;
  cursor: move;
  cursor: grab;
  color: #aaa;
  font-size: 24px;
  text-align: right;
  line-height: 6px;
  overflow: hidden;
}

.sortable-handle div {
  transform: translateY(-6px);
}
