.base {
  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  color: var(--ssColorBlack);
  background: var(--ssColorWhite);
  border: 1px solid var(--ssColorGray01);
  outline-color: var(--ssColorBlue);
  font-size: 14px;
  transition: all 0.14s;
}

.base:hover {
  border-color: var(--ssColorGray02);
}

.base:focus {
  outline: 0;
  background: var(--ssColorWhite);
  box-shadow: var(--ssFocusShadowInput);
  border-color: var(--ssColorBlue);

  /* border-color: transparent; */
  /* box-shadow: var(--ssFocusShadow); */
}

.base:disabled {
  pointer-events: none;
  background: var(--ssColorGray00);
  border: 1px solid var(--ssColorGray00);
}

/* .base.selected,
.base:not(:placeholder-shown):not(:focus) {
  background: var(--ssColorWhite);
  border: 1px solid var(--ssColorGray01);
  color: #000;
} */

.input {
  height: 40px;
  padding: 0 16px;
}

.textarea {
  display: block;

  font-family: inherit;

  line-height: 24px;
  padding: 8px 16px;

  resize: vertical;
}

.counter {
  position: absolute;
  right: 16px;
  bottom: 8px;
  color: var(--ssColorGray03);
  font-size: 14px;
  pointer-events: none;
}

.select {
  height: 40px;
  padding: 0 12px;
  color: var(--ssColorGray03);
}

.select option {
  background: var(--ssColorWhite);
}

.select option:checked {
  font-weight: bold;
}

.select.selected {
  color: var(--ssColorBlack);
}
