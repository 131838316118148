:root {
  /* spacing */
  --ssLayoutSpacingXXSmall: 4px;
  --ssLayoutSpacingXSmall: 8px;
  --ssLayoutSpacingSmall: 12px;
  --ssLayoutSpacingMedium: 16px;
  --ssLayoutSpacingLarge: 24px;
  --ssLayoutSpacingXLarge: 48px;
  --ssLayoutSpacingXXLarge: 64px;
}
