.label {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */

  height: 40px;
  line-height: 40px;
  font-size: 14px;

  cursor: pointer;
}

.label.inline {
  display: inline-flex;
}

.label.reverse {
  flex-direction: row-reverse;
}

.label.justify {
  justify-content: space-between;
}

.text {
  margin-left: 8px;
}

.reverse .text {
  margin-left: 0;
  margin-right: 8px;
}
