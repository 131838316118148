.item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;

  padding: 0 16px;

  font-size: 14px;
  border-radius: 20px 0 0 20px;

  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */

  transition: all 0.14s linear;
}

.item:hover {
  transition: all 0.04s linear;
}
